import React from "react";
import clsx from "clsx";
import RibbonsTwo from "@svg/RibbonsTwo";
import RibbonsOne from "@svg/RibbonsOne";
import { Container, Text, Image, Button, ButtonAlt } from "@atoms";

import { SITE } from "@constants";

const Hero = ({
  button,
  className: _className,
  copy,
  cover,
  hideRibbons,
  ribbonColor,
  image,
  lighten,
  link,
  altImageLayout,
}) => {
  return (
    <section
      className={clsx("relative z-30", _className, {
        "bg-gray-light": SITE === "robActon",
      })}
    >
      <div
        className={clsx("relative pt-32", {
          "pb-32": !lighten && !altImageLayout,
          "min-h-[50vh] md:pb-32 md:pt-48": !altImageLayout,
        })}
      >
        <Container className="relative z-20">
          <div className="flex flex-wrap gap-12 md:flex-nowrap">
            <div
              className={clsx(
                "relative z-10 flex w-full flex-col justify-center md:w-3/5",
                {
                  "lg:w-1/2": !altImageLayout,
                  "pb-12": altImageLayout,
                  "min-h-[28vw]": altImageLayout,
                }
              )}
            >
              <Text
                richText
                className={clsx(
                  "mt-4 max-w-lg prose-p:text-lg lg:prose-p:text-xl",
                  {
                    "prose-dark prose-headings:!text-white":
                      !lighten && !altImageLayout,
                  }
                )}
              >
                {copy}
              </Text>
              {(button?.url || link?.url) && (
                <div className="mt-8 flex items-center space-x-8">
                  {button?.url && (
                    <Button
                      size="md"
                      color={lighten ? "midnight" : "carnation"}
                      to={button.url}
                    >
                      {button.text}
                    </Button>
                  )}
                  {link?.url && (
                    <ButtonAlt to={link.url} icon="arrow">
                      {link.text}
                    </ButtonAlt>
                  )}
                </div>
              )}
            </div>
            {altImageLayout && image && (
              <div className="relative -bottom-4 z-0 -mt-32 flex w-[200%] items-end justify-center md:absolute md:-right-16 md:bottom-0 md:w-1/2 lg:w-[45%]">
                <Image image={image} aspectRatio={[1, 1]} />
              </div>
            )}
          </div>
        </Container>
        {!altImageLayout && image && (
          <div
            className={clsx("inset-0", {
              "relative -mt-16 aspect-[4/3] sm:aspect-video md:absolute md:mt-auto md:aspect-auto":
                lighten,
              absolute: !lighten,
              "md:left-[33.33%]": !cover && lighten && SITE === "default",
              "md:left-[10%]": !cover && lighten && SITE === "robActon",
            })}
          >
            {(SITE === "default" || !lighten) && (
              <div
                className={clsx("absolute inset-0 z-10 bg-gradient-to-b", {
                  "from-white md:bg-gradient-to-r md:via-transparent": lighten,
                  "from-midnight-dark bg-blend-multiply md:bg-gradient-to-br md:via-midnight-dark md:opacity-70":
                    !lighten,
                })}
              />
            )}
            <Image heightCrop image={image} fill />
          </div>
        )}
        {!hideRibbons && !altImageLayout && (
          <RibbonsTwo
            color={ribbonColor}
            className="pointer-events-none absolute inset-x-0 -bottom-4 z-10 md:-bottom-[5%]"
          />
        )}
        {!hideRibbons && altImageLayout && (
          <RibbonsOne
            color={ribbonColor}
            className="pointer-events-none absolute inset-x-0 -bottom-4 z-10 md:-bottom-[5%]"
          />
        )}
      </div>
    </section>
  );
};

Hero.defaultProps = {
  lighten: false,
  hideRibbons: false,
  cover: false,
};

export default Hero;
